<template>
    <div>
      <!-- <div class="banner_info" :style="`background: url(${bg}) center no-repeat;background-size: cover;`" v-if="bg"></div> -->
      <div class="banner_info" style="background: url(images/7_02.jpg) center no-repeat;background-size: cover;">
<!--        <img src="images/7_03.png">-->
      </div>
      <div class="infopage">
        <div class="container clearfix">
          <div class="left">
            <div class="box">
              <dl>
                <dt>通知公告</dt>
                <dd>Notice announcement</dd>
              </dl>
              <ul>
                <li v-for="item in tzgg.articleList" :key="item.id" @click="toDetail(item.id,tzgg.name)"><a href="javascript:;" >{{item.title}}</a></li>
              </ul>
            </div>
            <div class="box">
              <dl>
                <dt>法律法规</dt>
                <dd>laws and regulations</dd>
              </dl>
              <ul>
                <li v-for="item in flfg.articleList" :key="item.id" @click="toDetail(item.id,flfg.name)"><a href="javascript:;" >{{item.title}}</a></li>
              </ul>
            </div>
          </div>
          <div class="right">
            <div class="box">
              <div class="title flex">
                <div class="flex_item"><b>{{cate.name}}</b></div>
<!--                <a href="#" class="more">更多</a>-->
              </div>
              <div class="bd">
                <ul class="newslist">
                  <li v-for="(item,index) in cate.articleList" :key="index">
                    <div class="flex_item"><a href="javascript:;" @click="toDetail(item.id,cate.name)" >{{item.title}}</a></div>
                    <span>{{item.publishTime}}</span>
                  </li>
                </ul>

                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20, 40, 60, 80]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: "list",
  data(){
    return {
      cid:0,
      cate:{},
      pageNum:1,
      pageSize:20,
      total:0,
      leftData:[],
      tzgg:[],
      flfg:[],
      bg:''
    }
  },
  methods:{
    async getCateDetail(){
      let res = await this.$api.findData({
        columnId:this.cid,
        page:this.pageNum,
        size:this.pageSize
      })
      console.log(res);
      this.cate = res.data.data
      this.total = res.data.data.totalSize
      this.bg = res.data.data.imgUrl
      // document.title = "中汽认证中心-"+res.data.data.name
    },
    handleSizeChange(val){
      this.pageSize = val
      this.getCateDetail()
    },
    handleCurrentChange(val){
      this.pageNum = val
      this.getCateDetail()
    },
    toDetail(id,name){
      this.$router.push({path:'/view',query:{articleId:id,name:name}})
    },
  },
  async mounted() {
    this.cid = this.$route.query.pid;
    //获取左侧固定数据
    let res1 = await this.$api.getColumn({columnName:'通知公告'});
    this.tzgg = res1.data.data[0]
    let res2 = await this.$api.getColumn({columnName:'法律法规'});
    this.flfg = res2.data.data[0]
    this.getCateDetail()
  }
}
</script>

<style scoped>

</style>
